import {createRouter, createWebHistory} from 'vue-router'
import {authGuard} from "../common/auth/guard";
import store from "../store/index";
const routes = [
    {
        path: '/dashboard',
        name: 'home-framework',
        component: () => import('@/components/frameworks/DashboardFramework.vue'),
        beforeEnter: authGuard,
        redirect: {name: 'home'},
        children: [
            {
                name: 'home',
                path: '/',
                component: () => import('../views/HomeView.vue')
            },
            {
                path: '/profile',
                component: () => import('@/components/frameworks/UserSettingsFramework.vue'),
                children: [
                    {
                        name: 'profile',
                        path: '/profile',
                        meta: {title: "User Profile"},
                        component: () => import('../views/ProfileView.vue')
                    }
                ]
            },

            {
                path: '/reports',
                children: [
                    {
                        name: 'reports',
                        path: '/reports',
                        meta: {title: "Reporting"},
                        component: () => import('../views/landing-pages/ReportsView.vue')
                    },
                    {
                        path: '/reports/social',
                        children: [
                            {
                                path: '/reports/social/performance',
                                meta: {title: "Report - Social - Performance"},
                                component: () => import('../views/reports/ReportingContainer'),
                                children: [
                                    {
                                        name: 'reports-social-ads-performance',
                                        path: '/reports/social/performance',
                                        meta: {title: "Report - Social - Performance"},
                                        component: () => import('../views/reports/social/performance/DashboardView')
                                    },
                                    {
                                        name: 'reports-social-ads-performance-metric-comparison',
                                        path: '/reports/social/performance/metric-comparison',
                                        meta: {title: "Report - Social - Performance - Metric Comparison"},
                                        props: route => ({metrics: route.query.metrics}),
                                        component: () => import('../views/reports/social/performance/MetricComparison')
                                    },
                                    {
                                        name: 'reports-social-ads-performance-metric-breakdown',
                                        path: '/reports/social/performance/metric-breakdown',
                                        meta: {title: "Report - Social - Performance - Metric Breakdown"},
                                        props: route => ({metric: route.query.metric}),
                                        component: () => import('../views/reports/social/performance/MetricBreakdown')
                                    },
                                    {
                                        name: 'reports-social-ads-performance-touchpoints-overview-trended',
                                        path: '/reports/social/performance/touchpoints-overview-trended',
                                        meta: {title: "Report - Social - Performance - Touchpoints Overview (Trended)"},
                                        component: () => import('../views/reports/social/performance/TouchpointsOverviewTrended')
                                    },
                                    {
                                        name: 'reports-social-ads-performance-touchpoints-overview',
                                        path: '/reports/social/performance/touchpoints-overview',
                                        meta: {title: "Report - Social - Performance - Touchpoints Overview"},
                                        component: () => import('../views/reports/social/performance/TouchpointsOverview')
                                    },
                                    {
                                        name: 'reports-social-ads-performance-touchpoints-conversion-rates',
                                        path: '/reports/social/performance/touchpoints-conversion-rates',
                                        meta: {title: "Report - Social - Performance - Touchpoints Conversion Rates"},
                                        component: () => import('../views/reports/social/performance/TouchpointsConversionRates')
                                    },
                                    {
                                        name: 'reports-social-ads-performance-touchpoints-distribution',
                                        path: '/reports/social/performance/touchpoints-distribution',
                                        meta: {title: "Report - Social - Performance - Touchpoints Distribution"},
                                        component: () => import('../views/reports/social/performance/TouchpointsDistribution')
                                    },
                                    {
                                        name: 'reports-social-ads-performance-metric-breakdown-trended',
                                        path: '/reports/social/performance/metric-breakdown-trended',
                                        props: route => ({metric: route.query.metric}),
                                        meta: {title: "Report - Social - Performance - Metric Breakdown (Trended)"},
                                        component: () => import('../views/reports/social/performance/MetricBreakdownTrended')
                                    },
                                    {
                                        name: 'reports-social-ads-performance-cost-per-touchpoint',
                                        path: '/reports/social/performance/cost-per-touchpoint',
                                        meta: {title: "Report - Social - Performance - Cost Per Touchpoint"},
                                        component: () => import('../views/reports/social/performance/CostPerTouchpoint')
                                    },
                                    {
                                        name: 'reports-social-ads-performance-cost-per-touchpoint-trended',
                                        path: '/reports/social/performance/cost-per-touchpoint-trended',
                                        meta: {title: "Report - Social - Performance - Cost Per Touchpoint (Trended)"},
                                        component: () => import('../views/reports/social/performance/CostPerTouchpointTrended')
                                    }
                                ]
                            },

                            {
                                name: 'reports-social-ads-content',
                                path: '/reports/social/content',
                                meta: {title: "Report - Social - Content"},
                                component: () => import('../views/landing-pages/ReportContentView.vue')
                            }
                        ]
                    },
                    {
                        path: '/reports/search',
                        children: [
                            {
                                path: '/reports/search/performance',
                                meta: {title: "Report - Search - Performance"},
                                component: () => import('../views/reports/ReportingContainer'),
                                children: [
                                    {
                                        name: 'reports-search-ads-performance',
                                        path: '/reports/search/performance',
                                        meta: {title: "Report - Search - Performance"},
                                        component: () => import('../views/reports/search/performance/DashboardView')
                                    },
                                    {
                                        name: 'reports-search-ads-performance-metric-comparison',
                                        path: '/reports/search/performance/metric-comparison',
                                        meta: {title: "Report - Search - Performance - Metric Comparison"},
                                        props: route => ({metrics: route.query.metrics}),
                                        component: () => import('../views/reports/search/performance/MetricComparison')
                                    },
                                    {
                                        name: 'reports-search-ads-performance-metric-breakdown',
                                        path: '/reports/search/performance/metric-breakdown',
                                        meta: {title: "Report - Search - Performance - Metric Breakdown"},
                                        props: route => ({metric: route.query.metric}),
                                        component: () => import('../views/reports/search/performance/MetricBreakdown')
                                    },
                                    {
                                        name: 'reports-search-ads-performance-touchpoints-overview-trended',
                                        path: '/reports/search/performance/touchpoints-overview-trended',
                                        meta: {title: "Report - Search - Performance - Touchpoints Overview (Trended)"},
                                        component: () => import('../views/reports/search/performance/TouchpointsOverviewTrended')
                                    },
                                    {
                                        name: 'reports-search-ads-performance-touchpoints-overview',
                                        path: '/reports/search/performance/touchpoints-overview',
                                        meta: {title: "Report - Search - Performance - Touchpoints Overview"},
                                        component: () => import('../views/reports/search/performance/TouchpointsOverview')
                                    },
                                    {
                                        name: 'reports-search-ads-performance-touchpoints-conversion-rates',
                                        path: '/reports/search/performance/touchpoints-conversion-rates',
                                        meta: {title: "Report - Search - Performance - Touchpoints Conversion Rates"},
                                        component: () => import('../views/reports/search/performance/TouchpointsConversionRates')
                                    },
                                    {
                                        name: 'reports-search-ads-performance-touchpoints-distribution',
                                        path: '/reports/search/performance/touchpoints-distribution',
                                        meta: {title: "Report - Search - Performance - Touchpoints Distribution"},
                                        component: () => import('../views/reports/search/performance/TouchpointsDistribution')
                                    },
                                    {
                                        name: 'reports-search-ads-performance-metric-breakdown-trended',
                                        path: '/reports/search/performance/metric-breakdown-trended',
                                        props: route => ({metric: route.query.metric}),
                                        meta: {title: "Report - Search - Performance - Metric Breakdown (Trended)"},
                                        component: () => import('../views/reports/search/performance/MetricBreakdownTrended')
                                    },
                                    {
                                        name: 'reports-search-ads-performance-cost-per-touchpoint',
                                        path: '/reports/search/performance/cost-per-touchpoint',
                                        meta: {title: "Report - Search - Performance - Cost Per Touchpoint"},
                                        component: () => import('../views/reports/search/performance/CostPerTouchpoint')
                                    },
                                    {
                                        name: 'reports-search-ads-performance-cost-per-touchpoint-trended',
                                        path: '/reports/search/performance/cost-per-touchpoint-trended',
                                        meta: {title: "Report - Search - Performance - Cost Per Touchpoint (Trended)"},
                                        component: () => import('../views/reports/search/performance/CostPerTouchpointTrended')
                                    }
                                ]
                            },
                            {
                                name: 'reports-search-ads-content',
                                path: '/reports/search/content',
                                meta: {title: "Report - Search - Content"},
                                component: () => import('../views/landing-pages/ReportContentView.vue')
                            }
                        ]
                    },
                    {
                        path: '/reports/organic',
                        children: [
                            {
                                name: 'reports-organic-search-performance',
                                path: '/reports/organic/performance',
                                meta: {title: "Report - Organic - Performance"},
                                component: () => import('../views/reports/OrganicSearchView.vue')
                            },
                            {
                                name: 'reports-organic-search-content',
                                path: '/reports/organic/content',
                                meta: {title: "Report - Organic - Content"},
                                component: () => import('../views/landing-pages/ReportContentView.vue')
                            }
                        ]
                    },
                    {
                        path: '/reports/cross-channel',
                        children: [
                            {
                                path: '/reports/cross-channel/performance',
                                meta: {title: "Report - Cross-Channel - Performance"},
                                component: () => import('../views/reports/ReportingContainer'),
                                children: [
                                    {
                                        name: 'reports-cross-channel-performance',
                                        path: '/reports/cross-channel/performance',
                                        meta: {title: "Report - Cross-Channel - Performance"},
                                        component: () => import('../views/reports/cross-channel/performance/DashboardView')
                                    },
                                    {
                                        name: 'reports-cross-channel-performance-metric-comparison',
                                        path: '/reports/cross-channel/performance/metric-comparison',
                                        meta: {title: "Report - Cross-Channel - Performance - Metric Comparison"},
                                        props: route => ({metrics: route.query.metrics}),
                                        component: () => import('../views/reports/cross-channel/performance/MetricComparison')
                                    },
                                    {
                                        name: 'reports-cross-channel-performance-metric-breakdown',
                                        path: '/reports/cross-channel/performance/metric-breakdown',
                                        meta: {title: "Report - Cross-Channel - Performance - Metric Breakdown"},
                                        props: route => ({metric: route.query.metric}),
                                        component: () => import('../views/reports/cross-channel/performance/MetricBreakdown')
                                    },
                                    {
                                        name: 'reports-cross-channel-performance-touchpoints-overview-trended',
                                        path: '/reports/cross-channel/performance/touchpoints-overview-trended',
                                        meta: {title: "Report - Cross-Channel - Performance - Touchpoints Overview (Trended)"},
                                        component: () => import('../views/reports/cross-channel/performance/TouchpointsOverviewTrended')
                                    },
                                    {
                                        name: 'reports-cross-channel-performance-touchpoints-overview',
                                        path: '/reports/cross-channel/performance/touchpoints-overview',
                                        meta: {title: "Report - Cross-Channel - Performance - Touchpoints Overview"},
                                        component: () => import('../views/reports/cross-channel/performance/TouchpointsOverview')
                                    },
                                    {
                                        name: 'reports-cross-channel-performance-touchpoints-conversion-rates',
                                        path: '/reports/cross-channel/performance/touchpoints-conversion-rates',
                                        meta: {title: "Report - Cross-Channel - Performance - Touchpoints Conversion Rates"},
                                        component: () => import('../views/reports/cross-channel/performance/TouchpointsConversionRates')
                                    },
                                    {
                                        name: 'reports-cross-channel-performance-touchpoints-distribution',
                                        path: '/reports/cross-channel/performance/touchpoints-distribution',
                                        meta: {title: "Report - Cross-Channel - Performance - Touchpoints Distribution"},
                                        component: () => import('../views/reports/cross-channel/performance/TouchpointsDistribution')
                                    },
                                    {
                                        name: 'reports-cross-channel-performance-metric-breakdown-trended',
                                        path: '/reports/cross-channel/performance/metric-breakdown-trended',
                                        props: route => ({metric: route.query.metric}),
                                        meta: {title: "Report - Cross-Channel - Performance - Metric Breakdown (Trended)"},
                                        component: () => import('../views/reports/cross-channel/performance/MetricBreakdownTrended')
                                    },
                                    {
                                        name: 'reports-cross-channel-performance-cost-per-touchpoint',
                                        path: '/reports/cross-channel/performance/cost-per-touchpoint',
                                        meta: {title: "Report - Cross-Channel - Performance - Cost Per Touchpoint"},
                                        component: () => import('../views/reports/cross-channel/performance/CostPerTouchpoint')
                                    },
                                    {
                                        name: 'reports-cross-channel-performance-cost-per-touchpoint-trended',
                                        path: '/reports/cross-channel/performance/cost-per-touchpoint-trended',
                                        meta: {title: "Report - Cross-Channel - Performance - Cost Per Touchpoint (Trended)"},
                                        component: () => import('../views/reports/cross-channel/performance/CostPerTouchpointTrended')
                                    }
                                ]
                            },
                            {
                                name: 'reports-cross-channel-content',
                                path: '/reports/cross-channel/content',
                                meta: {title: "Report - Cross-Channel - Content"},
                                component: () => import('../views/landing-pages/ReportContentView.vue')
                            },
                            {
                                name: 'reports-cross-channel-optimization',
                                path: '/reports/cross-channel/optimization',
                                meta: {title: "Report - Cross-Channel - Optimization"},
                                component: () => import('../views/reports/CrossChannelOptimizationView.vue')
                            }
                        ]
                    },
                    {
                        name: 'reports-content',
                        path: '/reports/content',
                        meta: {title: "Report - Content"},
                        component: () => import('../views/landing-pages/ReportContentView.vue')
                    },
                ]
            },

                {
                path: '/optimise',
                meta: {title: "Optimise"},
                children: [
                    {
                        name: 'optimise',
                        path: '/optimise',
                        meta: {title: "Optimise"},

                        component: () => import('../views/landing-pages/OptimiseView.vue')
                    },
                    {
                        path:"/optimise/performance",
                        children:[
                             {
                        name: 'optimise-dashboard',
                        path: '/optimise/performance',
                        meta: {title: "Optimise Performance"},
                        component: () => import('../views/optimise/DashboardView.vue')
                    },
                                 {
                        path: '/optimise/settings',
                        component: () => import('../views/optimise/SettingsView'),
                        redirect: {name: 'optimise-settings-rules'},
                        children: [
                            {
                                    path: '/optimise/settings',
                                children: [
                                    {
                                        path: '/optimise/settings/rules',
                                        meta: {title: "Manage Optimisation Rules"},
                                        children: [
                                        {
                                            name: 'optimise-settings-rules',
                                            path: '/optimise/settings/rules',
                                            meta: {title: "Budget Optimisation Rules"},
                                            component: () => import('../views/optimise/settings/ListRulesView.vue'),
                                        },
                                        {
                                            name: 'optimise-settings-brand-rule-add',
                                            path: '/optimise/settings/brands/:brand_id/rules/add',
                                            meta: {title: "New Budget Optimisation Rule"},
                                            props: route => ({brand_id: route.params.brand_id, clone:route.query.clone}),
                                            component: () => import('../views/optimise/settings/AddRuleView.vue')
                                        },
                                        {
                                            name: 'optimise-settings-brand-rule',
                                            path: '/optimise/settings/brands/:brand_id/rules/:id',
                                            meta: {title: "Edit Budget Optimisation Rule"},
                                            props: route => ({brand_id: route.params.brand_id, id: route.params.id}),
                                            component: () => import('../views/optimise/settings/AddRuleView.vue')
                                        }
                                    ]
                                    },
                                ]
                            }]
                        }
                        ]
                    }


                ]
            },

            {
                name: 'protect',
                path: '/protect',
                meta: {title: "Protect"},
                component: () => import('../views/landing-pages/ProtectView.vue')
            },
            {
                path: '/journey',
                meta: {title: "Journey"},
                children: [
                    {
                        path: '/protect/social/outcomes',
                        meta: {title: "Protect - Social - Outcomes"},
                        component: () => import('../views/reports/ReportingContainer'),
                        children: [
                            {
                                name: 'protect-social-outcomes',
                                path: '/protect/social/outcomes',
                                meta: {title: "Protect - Social - Outcomes"},
                                component: () => import('../views/protect/outcomes/social/FunnelView')
                            }
                        ]
                    },
                    {
                        path: '/protect/search/outcomes',
                        meta: {title: "Protect - Search - Outcomes"},
                        component: () => import('../views/reports/ReportingContainer'),
                        children: [
                            {
                                name: 'protect-search-outcomes',
                                path: '/protect/search/outcomes',
                                meta: {title: "Protect - Search - Outcomes"},
                                component: () => import('../views/protect/outcomes/search/FunnelView')
                            }
                        ]
                    },
                    {
                        path: '/protect/cross-channel/outcomes',
                        meta: {title: "Protect - Cross-Channel - Outcomes"},
                        component: () => import('../views/reports/ReportingContainer'),
                        children: [
                            {
                                name: 'protect-cross-channel-outcomes',
                                path: '/protect/cross-channel/outcomes',
                                meta: {title: "Protect - Cross-Channel - Outcomes"},
                                component: () => import('../views/protect/outcomes/cross-channel/FunnelView')
                            }
                        ]
                    },
                ]
            },
                {
                path: '/budgeting',
                meta: {title: "Budgeting"},
                children: [
                    {
                        path: '/protect/settings',
                        component: () => import('../views/protect/SettingsView'),
                        redirect: {name: 'protect-settings-budgets'},
                        children: [
                            {
                                path: '/protect/settings/budgets',
                                meta: {title: "Manage Budgets"},
                                children: [
                                    {
                                        name: 'protect-settings-budgets',
                                        path: '/protect/settings/budgets',
                                        meta: {title: "Manage Budgets"},
                                        component: () => import('../views/protect/budget/settings/ListBudgetsView.vue'),
                                    },
                                    {
                                        name: 'protect-settings-brand-budget-add',
                                        path: '/protect/settings/brands/:brand_id/budgets/add',
                                        meta: {title: "Add New Budget"},
                                        props: route => ({brand_id: route.params.brand_id, clone: route.query.clone}),
                                        component: () => import('../views/protect/budget/settings/AddBudgetView.vue')
                                    },
                                    {
                                        name: 'protect-settings-brand-budget',
                                        path: '/protect/settings/brands/:brand_id/budgets/:id',
                                        meta: {title: "View Budget"},
                                        props: route => ({brand_id: route.params.brand_id, id: route.params.id}),
                                        component: () => import('../views/protect/budget/settings/AddBudgetView.vue')
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: '/protect/social/budget',
                        meta: {title: "Protect - Social - Budget"},
                        component: () => import('../views/reports/ReportingContainer'),
                        children: [
                            {
                                name: 'protect-social-budget',
                                path: '/protect/social/budget',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/DashboardView')
                            },
                            {
                                name: 'protect-social-budget-report-spend-forecast',
                                path: '/protect/social/budget/period-spend-forecast',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/SpendForecast')
                            },
                            {
                                name: 'protect-social-budget-report-required-daily-spend',
                                path: '/protect/social/budget/period-required-daily-spend',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/RequiredDailySpend')
                            },
                            {
                                name: 'protect-social-budget-report-expected-outcome',
                                path: '/protect/social/budget/period-expected-outcome',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/ExpectedOutcome')
                            },
                            {
                                name: 'protect-social-budget-report-budget-status',
                                path: '/protect/social/budget/period-budget-status',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/BudgetStatus')
                            },
                            {
                                name: 'protect-social-budget-report-pacing-daily',
                                path: '/protect/social/budget/period-pacing-daily',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/PeriodPacingDaily')
                            },
                            {
                                name: 'protect-social-budget-report-pacing-vs-budget',
                                path: '/protect/social/budget/period-pacing-vs-budget',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/PacingVsBudget')
                            },
                            {
                                name: 'protect-social-budget-report-spend-variance',
                                path: '/protect/social/budget/period-spend-variance',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/SpendVariance.vue')
                            },
                            {
                                name: 'protect-social-budget-report-pacing-incremental',
                                path: '/protect/social/budget/period-pacing-incremental',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/PeriodPacingIncremental')
                            },
                            {
                                name: 'protect-social-budget-report-campaign-pacing-daily',
                                path: '/protect/social/budget/campaign-pacing-daily',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/CampaignPacingDaily')
                            },
                            {
                                name: 'protect-social-budget-report-campaign-pacing-incremental',
                                path: '/protect/social/budget/campaign-pacing-incremental',
                                meta: {title: "Protect - Social - Budget"},
                                component: () => import('../views/protect/budget/social/CampaignPacingIncremental')
                            },
                        ]
                    },
                    {
                        path: '/protect/search/budget',
                        meta: {title: "Protect - Search - Budget"},
                        component: () => import('../views/reports/ReportingContainer'),
                        children: [
                            {
                                name: 'protect-search-budget',
                                path: '/protect/search/budget',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/DashboardView')
                            },
                            {
                                name: 'protect-search-budget-report-spend-forecast',
                                path: '/protect/search/budget/period-spend-forecast',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/SpendForecast')
                            },
                            {
                                name: 'protect-search-budget-report-required-daily-spend',
                                path: '/protect/search/budget/period-required-daily-spend',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/RequiredDailySpend')
                            },
                            {
                                name: 'protect-search-budget-report-expected-outcome',
                                path: '/protect/search/budget/period-expected-outcome',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/ExpectedOutcome')
                            },
                            {
                                name: 'protect-search-budget-report-budget-status',
                                path: '/protect/search/budget/period-budget-status',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/BudgetStatus')
                            },
                            {
                                name: 'protect-search-budget-report-pacing-daily',
                                path: '/protect/search/budget/period-pacing-daily',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/PeriodPacingDaily')
                            },
                            {
                                name: 'protect-search-budget-report-spend-variance',
                                path: '/protect/search/budget/period-spend-variance',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/SpendVariance.vue')
                            },
                            {
                                name: 'protect-search-budget-report-pacing-vs-budget',
                                path: '/protect/search/budget/period-pacing-vs-budget',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/PacingVsBudget')
                            },
                            {
                                name: 'protect-search-budget-report-pacing-incremental',
                                path: '/protect/search/budget/period-pacing-incremental',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/PeriodPacingIncremental')
                            },
                            {
                                name: 'protect-search-budget-report-campaign-pacing-daily',
                                path: '/protect/search/budget/campaign-pacing-daily',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/CampaignPacingDaily')
                            },
                            {
                                name: 'protect-search-budget-report-campaign-pacing-incremental',
                                path: '/protect/search/budget/campaign-pacing-incremental',
                                meta: {title: "Protect - Search - Budget"},
                                component: () => import('../views/protect/budget/search/CampaignPacingIncremental')
                            },
                        ]
                    },
                    {
                        path: '/protect/cross-channel/budget',
                        meta: {title: "Protect - Cross-Channel - Budget"},
                        component: () => import('../views/reports/ReportingContainer'),
                        children: [
                            {
                                name: 'protect-cross-channel-budget',
                                path: '/protect/cross-channel/budget',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/DashboardView')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-spend-forecast',
                                path: '/protect/cross-channel/budget/period-spend-forecast',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/SpendForecast')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-required-daily-spend',
                                path: '/protect/cross-channel/budget/period-required-daily-spend',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/RequiredDailySpend')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-expected-outcome',
                                path: '/protect/cross-channel/budget/period-expected-outcome',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/ExpectedOutcome')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-budget-status',
                                path: '/protect/cross-channel/budget/period-budget-status',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/BudgetStatus')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-pacing-daily',
                                path: '/protect/cross-channel/budget/period-pacing-daily',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/PeriodPacingDaily')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-spend-variance',
                                path: '/protect/cross-channel/budget/period-spend-variance',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/SpendVariance.vue')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-pacing-vs-budget',
                                path: '/protect/cross-channel/budget/period-pacing-vs-budget',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/PacingVsBudget')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-pacing-incremental',
                                path: '/protect/cross-channel/budget/period-pacing-incremental',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/PeriodPacingIncremental')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-campaign-pacing-daily',
                                path: '/protect/cross-channel/budget/campaign-pacing-daily',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/CampaignPacingDaily')
                            },
                            {
                                name: 'protect-cross-channel-budget-report-campaign-pacing-incremental',
                                path: '/protect/cross-channel/budget/campaign-pacing-incremental',
                                meta: {title: "Protect - Cross-Channel - Budget"},
                                component: () => import('../views/protect/budget/cross-channel/CampaignPacingIncremental')
                            },
                        ]
                    },
                ]

            },
            {
                path: '/monitoring',
                meta: {title: "Monitoring"},
                children: [
                    {
                        path: '/protect/settings',
                        component: () => import('../views/protect/SettingsView'),
                        redirect: {name: 'protect-settings-budgets'},
                        children: [

                            {
                                path: '/protect/settings/kpis',
                                meta: {title: "Manage KPIs"},
                                children: [
                                    {
                                        name: 'protect-settings-kpis',
                                        path: '/protect/settings/kpis',
                                        meta: {title: "Manage KPIs"},
                                        component: () => import('../views/protect/performance/settings/ListKpisView.vue'),
                                    },
                                    {
                                        name: 'protect-settings-brand-kpi-add',
                                        path: '/protect/settings/brands/:brand_id/kpis/add',
                                        meta: {title: "Add New KPI"},
                                        props: route => ({brand_id: route.params.brand_id, clone:route.query.clone}),
                                        component: () => import('../views/protect/performance/settings/AddKpiView.vue')
                                    },
                                    {
                                        name: 'protect-settings-brand-kpi',
                                        path: '/protect/settings/brands/:brand_id/kpis/:id',
                                        meta: {title: "View KPI"},
                                        props: route => ({brand_id: route.params.brand_id, id: route.params.id}),
                                        component: () => import('../views/protect/performance/settings/AddKpiView.vue')
                                    }
                                ]
                            }
                        ]
                    },
                         {
                                path: '/protect/social/performance',
                                meta: {title: "Protect - Social - Performance"},
                                component: () => import('../views/reports/ReportingContainer'),
                                children: [
                                    {
                                        name: 'protect-social-performance',
                                        path: '/protect/social/performance',
                                        meta: {title: "Protect - Social - Performance"},
                                        component: () => import('../views/protect/performance/social/DashboardView')
                                    },
                                ]
                            },
                          {
                                        path: '/protect/search/performance',
                                        meta: {title: "Protect - Search - Performance"},
                                        component: () => import('../views/reports/ReportingContainer'),
                                        children: [
                                            {
                                                name: 'protect-search-performance',
                                                path: '/protect/search/performance',
                                                meta: {title: "Protect - Search - Performance"},
                                                component: () => import('../views/protect/performance/search/DashboardView')
                                            }
                                        ]
                                    },
                        {
                                        path: '/protect/cross-channel/performance',
                                        meta: {title: "Protect - Cross-Channel - Performance"},
                                        component: () => import('../views/reports/ReportingContainer'),
                                        children: [
                                            {
                                                name: 'protect-cross-channel-performance',
                                                path: '/protect/cross-channel/performance',
                                                meta: {title: "Protect - Cross-Channel - Performance"},
                                                props: {
                                                    title: "Metric Change Tracking",
                                                    subtitle: "Protect > Cross-Channel > Performance",
                                                    platforms: [
                                                        'Meta',
                                                        'Google'
                                                    ]
                                                },
                                                component: () => import('../views/protect/performance/cross-channel/DashboardView')
                                            }
                                        ]

                            },
                ]
            },
            {
                path: '/discover',
                children: [
                    {
                        name: 'discover',
                        path: '/discover',
                        meta: {title: "Discover"},
                        component: () => import('../views/landing-pages/DiscoverView.vue')
                    },
                     {
                        name:'discover-social',
                        path: '/discover/social',
                        meta: {title: "Discover - Social"},
                        props: route => ({module: route.query.module}),
                        component: () => import('../views/discover/social/DashboardView.vue')
                    },
                        {
                        name:'discover-search',
                        path: '/discover/search',
                        meta: {title: "Discover - Search"},
                        props: route => ({module: route.query.module}),
                        component: () => import('../views/discover/search/DashboardView.vue')
                    },
                        {
                        name:'discover-cross-channel',
                        path: '/discover/cross-channel',
                        meta: {title: "Discover - Cross-Channel"},
                        props: route => ({module: route.query.module}),
                        component: () => import('../views/discover/cross-channel/DashboardView.vue')
                    },
                ]
            },
            {
                name: 'terms',
                path: '/terms',
                component: () => import('../views/pages/TermsView.vue')
            },
            {
                name: 'packages',
                path: '/packages',
                meta: {title: "Upgrade"},
                component: () => import('../views/pages/PackagesView.vue')
            },
            {
                path: '/settings',
                name: 'settings',
                component: () => import('../views/settings/SettingsView.vue'),
                redirect: {name: 'settings-connections'},
                children: [
                    {
                        path: '/settings/users',

                        meta: {title: "User Management"},
                        children: [
                            {
                                name: 'settings-users',
                                path: '',
                                component: () => import('../views/settings/users/ListUsersView.vue'),
                                meta: {title: "User Management"},
                            },
                            {
                                name: 'settings-users-invite',
                                path: 'invite',
                                component: () => import('../views/settings/users/InviteView.vue'),
                                meta: {title: "User Management"},
                            }
                        ]
                    },
                    {
                        path: '/settings/connections',
                        meta: {title: "Connected Platforms & Accounts"},
                        children: [
                            {
                                name: "settings-connections",
                                path: '/settings/connections',
                                component: () => import('../views/settings/ConnectionsView.vue'),
                                meta: {title: "Platform Activation"},
                            },
                            {
                                name: "settings-connection",
                                path: '/settings/brands/:brand_id/connections/:id',
                                props: route => ({id: route.params.id, brand_id: route.params.brand_id}),
                                component: () => import('../views/settings/connections/ConnectionView.vue'),
                                meta: {title: "Platform Activation"},
                            },
                            {
                                name: "settings-connections-facebook",
                                path: '/settings/brands/:brand_id/connections/facebook',
                                props: route => ({brand_id: route.params.brand_id, type: 'facebook'}),
                                component: () => import('../views/settings/connections/ConnectionView.vue'),
                                meta: {title: "Platform Activation"},
                            },
                            {
                                name: "settings-connections-instagram",
                                path: '/settings/connections/instagram',
                                component: () => import('../views/settings/connections/InstagramView.vue'),
                                meta: {title: "Platform Activation"},
                            },
                            {
                                name: "settings-connections-google-ads",
                                path: '/settings/brands/:brand_id/connections/google-ads',
                                props: route => ({brand_id: route.params.brand_id, type: 'google-ads'}),
                                component: () => import('../views/settings/connections/ConnectionView.vue'),
                                meta: {title: "Platform Activation"},
                            },
                            {
                                name: "settings-connections-tiktok",
                                path: '/settings/brands/:brand_id/connections/tiktok',
                                props: route => ({brand_id: route.params.brand_id, type: 'tiktok'}),
                                component: () => import('../views/settings/connections/ConnectionView.vue'),
                                meta: {title: "Platform Activation"},
                            },
                            {
                                name: "settings-connections-microsoft-ads",
                                path: '/settings/brands/:brand_id/connections/microsoft-ads',
                                props: route => ({brand_id: route.params.brand_id, type: 'microsoft-ads'}),
                                component: () => import('../views/settings/connections/ConnectionView.vue'),
                                meta: {title: "Platform Activation"},
                            }
                        ]
                    },
                    {
                        path: '/settings/brands',
                        meta: {title: "Manage Brands"},
                        children: [
                            {
                                name: "settings-brand-invitations",
                                path: '/brand_invitations/:id',
                                props: true,
                                component: () => import('../views/settings/brands/ImportBrandView.vue'),
                                meta: {title: "Manage Brands"},
                            },
                            {
                                name: "settings-brands",
                                path: '/settings/brands',
                                component: () => import('../views/settings/brands/ListBrandsView.vue'),
                                meta: {title: "Manage Brands"},
                            },
                            {
                                name: "settings-brands-add",
                                path: '/settings/brands/add',
                                component: () => import('../views/settings/brands/AddBrandView.vue'),
                                meta: {title: "Manage Brands"},
                            },
                            {
                                name: "settings-brands-share",
                                path: '/settings/brands/:id/share',
                                props: true,
                                component: () => import('../views/settings/brands/ShareBrandView.vue'),
                                meta: {title: "Manage Brands"},
                            },
                            {
                                name: "settings-brands-edit",
                                path: '/settings/brands/:id',
                                props: true,
                                component: () => import('../views/settings/brands/AddBrandView.vue'),
                                meta: {title: "Manage Brands"},
                            }
                        ]
                    },
                    {
                        name: 'settings-platform',
                        path: '/settings/platform',
                        component: () => import('../views/settings/PlatformView.vue'),
                        meta: {title: "Platform Branding"},

                    }
                ]
            }
        ]
    },
    {
        path: '/login',
        name: 'login-framework',
        component: () => import('@/components/frameworks/LoginFramework.vue'),
        children: [
            {
                name: 'login',
                path: '/login',
                component: () => import('../views/LoginView.vue')
            },
            {
                path: '/login/reset',
                name: 'login-reset',
                component: () => import('../views/ForgottenPasswordView.vue')
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('../views/RegisterView.vue')
            }
        ]
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../views/LogoutView.vue')
    }

]

const router = createRouter({
                                history: createWebHistory(process.env.BASE_URL),
                                scrollBehavior(to)
                                {
                                    if(to.hash && document.querySelector(to.hash))
                                    {
                                        return {el: to.hash};
                                    }
                                    else
                                        return {top: 0}

                                },
                                routes
                            })
router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    store.commit("setOutdated", true);
  }
})

export default router
